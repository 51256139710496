<template>
    <div id="app">
      <CRow>
        <CCol sm="8" md="8">
            <CCard>
              <CCardHeader>
                <h4>DBS-MCA Banner</h4>
                <span>Required dimension (width*height) :  1000 x 406</span> 
              </CCardHeader>
              <CCardBody>
                  <CForm>

                    <CRow>
                      <CCol col="12" class="text-left">
                        <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                      </CCol>
                    </CRow>
                    <div class="text-center">
                        <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
                    </div>
                  
                    <b-row>
                      <b-col sm="3">
                         <label for="input-small" >Upload Banner</label>
                      </b-col>
                      <b-col sm="9">
                          <input type="file" :v-model="srcFile" ref="myFile" accept="image/x-png" @change="onFileChangedImg">
                          <b-button-group>
                          <CButton @click="onUplonUploadImg" :disabled="!isDisabledbtnImg" color="success">Upload</CButton>
                          <CButton  v-on:click="actionShowImg()" color="warning">Show</CButton>
                          <CButton  v-on:click="actionDownloadImg()" color="primary">Download</CButton>
                          </b-button-group>
                      </b-col>
                    </b-row><br/>

                    <b-row>
                      <b-col sm="3">
                         <label for="input-small" >Preview Banner </label>
                         <!-- {{image.width}} * {{image.height}} -->
                      </b-col>
                      <b-col sm="9">
                          <b-img @error="onImageLoadFailure($event)" thumbnail fluid v-bind="imgProps" :src="'data:image/png;base64,'+ imgBanner " alt=""></b-img>
                      </b-col>
                    </b-row><br/>

                    <b-row>
                      <b-col sm="3">
                      </b-col>
                      <b-col sm="9">
                          <CButton  v-on:click="actionRefresh()" color="primary">Refresh Preview</CButton>
                      </b-col>
                    </b-row><br/>

                  </CForm>
              </CCardBody>
            </CCard>
          </CCol>
         </CRow>
    </div>
    </template>

    <script>
     import TrService from '@/api/TransactService.js';

      export default {
        name: 'createdbsmcabanner',
        components: {
        },
        data() {
          return {
              image:{
                size:0,
                height:0,
                width:0
              },
              srcFile:'',
              imgType : '',
              imgBanner:'',
              selectedFile: null,
              isDisabledbtnImg:false,
              imgProps: {  width: 200, height: 200  },              
              isDisabledName: false,
              spinner:false,
              seen:false,
              msg :'',
              color:'',
              count:1
            };
        },
        created () {
            this.actionShowImg();
        },
        methods: {

          getTimestampInSeconds () {
            return Math.floor(Date.now() / 1000);
          },

          onImageLoadFailure (event) {
              event.target.src = 'img/default.png'
          },

          onFileChangedImg(){
             this.selectedFile = event.target.files[0];
             this.isDisabledbtnImg = true;
          },

          actionRefresh(){
              this.actionShowImg();
          },

          actionShowImg(){            
            this.downloadDbsMcaBanner("png","true");
          },

          actionDownloadImg(){            
            this.downloadDbsMcaBanner("png","false");
          },

          async onUplonUploadImg() {

              this.spinner = true;
              this.imageLoaded = false;
              let file = this.$refs.myFile.files[0];

              if(!file || file.type.indexOf('image/') !== 0) return;

              this.image.size = file.size;
              let reader = new FileReader();

                reader.readAsDataURL(file);
                reader.onload = evt => {
                  let img = new Image();
                  img.onload = () => {
                    this.image.width  = img.width;
                    this.image.height = img.height;
                    this.imageLoaded  = true;
                  }
                  img.src = evt.target.result;
                }
                reader.onerror = evt => { console.error(evt); }
                setTimeout( () =>  this.actionSubmit() , 5000);
          },

          async actionSubmit(){                         

              if(this.image.width === 1000 && this.image.height === 406) {
                const formData = new FormData()
                formData.append('resource', this.selectedFile, this.selectedFile.name);                
                try {
                    const resp = await TrService.uploadDbsMcaBanner(formData);
                    if(resp.statusCode==="S0000"){
                       this.msg   = "Upload Banner Successfully!";
                       this.color = 'success';
                       this.actionShowImg();
                    } else {
                       this.msg   =  !resp.data.message ? "Upload Banner Failed!" : resp.data.message;
                       this.color = 'danger';
                    }
                    this.seen  = true;
                    setTimeout( () =>  this.seen=false , 5000);  this.spinner = false;
                } catch (error) { console.log(error); }
              } else {
                  this.msg  = "Failed, detected dimension " + this.image.width +" * "+ this.image.height;
                  this.color = 'warning'; this.seen  = true;  this.spinner = false;
                  setTimeout( () =>  this.seen=false , 5000);
              };
          },
          
          downloadDbsMcaBanner: function(type,show) {
              TrService.downloadDbsMcaBanner().then(resp => { 
                  if(show==="true"){
                      this.imgBanner = resp;  this.imgType = type;
                  } else {
                      var ImageBase64 = resp;
                      var a = document.createElement("a");
                      a.href = "data:image/png; base64," + ImageBase64;
                      a.download = "dbs-mca-banner-"+this.getTimestampInSeconds(); 
                      a.click();
                  }
              }, error => {
                console.log('error');
              });
          },

        }
      }
    </script>